<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">
        {{ type === 'student' ? '学生请假' : '个人请假' }}
      </div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <van-form
        input-align="right"
        error-message-align="right"
        show-error-message
        show-error
        ref="form"
        class="form"
      >
        <van-field
          :border="false"
          readonly
          clickable
          name="startTime"
          :value="form.startTime"
          label="开始时间"
          right-icon="arrow-down"
          :rules="[
            {
              validator: validatorStartTime,
              message: '开始时间不能晚于结束时间'
            },
            {
              required: true,
              message: '请选择开始时间'
            }
          ]"
          placeholder="请选择开始时间"
          @click="showStartCalendar = true"
        />
        <!-- <van-calendar
          v-model="showStartCalendar"
          color="rgba(74, 129, 196)"
          @confirm="(e) => onCalendarConfirm(e, 'start')"
        /> -->
        <van-field
          readonly
          :border="false"
          clickable
          name="endTime"
          :value="form.endTime"
          label="结束时间"
          right-icon="arrow-down"
          :rules="[
            {
              validator: validatorEndTime,
              message: '结束时间不能早于开始时间'
            },
            {
              required: true,
              message: '请选择结束时间'
            }
          ]"
          placeholder="请选择结束时间"
          @click="showEndCalendar = true"
        />
        <!-- <van-calendar
          v-model="showEndCalendar"
          :min-date="endMinDate"
          color="rgba(74, 129, 196)"
          @confirm="(e) => onCalendarConfirm(e, 'end')"
        /> -->
        <van-field
          v-if="type === 'student'"
          :border="false"
          v-model.trim="form.name"
          name="name"
          :rules="[
            { required: true, message: '请选择请假学生' },
            {
              validator: validatorStudent,
              message: '请选择请假学生'
            }
          ]"
          label="请假学生"
          placeholder="请输入学生"
          @input="showStudentList"
          ref="studentInput"
          autocomplete="off"
        />
        <!-- 学生列表 -->
        <div v-if="isShowStudentList" class="student-list" ref="studentList">
          <div
            class="student-list-item"
            v-for="item in filteredStudentList"
            :key="item.id"
            @click="onChooseStudent(item)"
          >
            {{ item.name }} {{ item.phone }}
          </div>
        </div>
        <van-field
          :border="false"
          v-model.trim="form.description"
          rows="1"
          autosize
          name="description"
          :rules="[{ required: true, message: '请填写请假理由' }]"
          label="请假理由"
          type="textarea"
          placeholder="请输入请假理由"
        />
      </van-form>
      <div
        style="
          width: 100%;
          padding: 30px 0 30px 0px;
          background-color: #f7f7f7;
          display: flex;
          justify-content: center;
        "
      >
        <van-button class="apply-btn" @click="openDialog">提交</van-button>
      </div>
      <!-- 开始时间 -->
      <van-popup v-model="showStartCalendar" position="bottom">
        <van-datetime-picker
          type="date"
          :min-date="minDate"
          :formatter="formatterTime"
          @confirm="onStartTimeConfirm"
          @cancel="showStartCalendar = false"
        />
      </van-popup>
      <!-- 结束时间 -->
      <van-popup v-model="showEndCalendar" position="bottom">
        <van-datetime-picker
          type="date"
          :min-date="originEndTimeMinDate"
          :max-date="originEndTimeMaxDate"
          :formatter="formatterTime"
          @confirm="onEndTimeConfirm"
          @cancel="showEndCalendar = false"
        />
      </van-popup>
    </div>
    <van-dialog
      v-model="showDialog"
      show-cancel-button
      show-confirm-button
      :close-on-click-overlay="false"
      class="submit-dialog"
      confirm-button-color="#FA5050"
      cancel-button-color="#999999"
      overlay-class="overlay"
      @cancel="(showDialog = false), (showLoading = false)"
      @confirm="onSubmit"
    >
      <template #title>
        <div class="title">温馨提示</div>
      </template>
      <div class="body">确定提交申请吗?</div>
    </van-dialog>
    <van-overlay :show="showLoading">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { addLeave, getStudentList } from '@/api/leave';
import moment from 'moment';
import { Toast } from 'vant';
import dayjs from 'dayjs';
const date = new Date();
export default {
  name: 'AskLeave',
  data() {
    return {
      manager: '',
      title: '',
      showStartCalendar: false,
      showEndCalendar: false,
      timeRange: '',
      showPicker: false,
      cancel,
      form: {
        description: '',
        manager: '',
        startTime: '',
        endTime: ''
      },
      startDate: null,
      endDate: null,
      endMinDate: date,
      type: '',
      // 开始最小时间默认今天
      minDate: new Date(),
      showLoading: false,
      isShowStudentList: false,
      currentStudentList: [], // 所有学生列表
      filteredStudentList: [], // 根据输入内容过滤后的学生列表
      showDialog: false
    };
  },
  async created() {
    this.type = this.$route.query.type;
    console.log(this.type, 'type');
    const { role, manager } = this.$store.getters;
    console.log(role, manager);
    if (role === 1) {
      this.title = '班主任';
    } else {
      this.title = '主管';
    }
    this.form.manager = manager;
    const res = await getStudentList();
    this.currentStudentList = res.data;
  },
  computed: {
    // 结束时间的最小时间
    originEndTimeMinDate() {
      // 如果开始时间为空，结束时间最小时间为今天
      if (!this.form.startTime) {
        return new Date();
      }
      // 如果开始时间不为空，结束时间最小时间为开始时间
      return dayjs(this.form.startTime).toDate();
    },
    // 结束时间的最大时间
    originEndTimeMaxDate() {
      // 如果开始时间为空，结束时间最大时间为开始时间的后十年

      if (!this.form.originStartTime) {
        return dayjs().add(10, 'year').toDate();
      } else {
        // 如果开始时间不为空,结束时间为开始时间的后十年
        return dayjs(this.form.originStartTime).add(10, 'year').toDate();
      }
    }
  },
  methods: {
    showStudentList(value) {
      // 根据输入内容过滤学生列表
      if (!value) {
        this.isShowStudentList = false;
        return;
      }

      this.filteredStudentList = this.currentStudentList.filter(
        (item) => item.name.includes(value) || item.phone.includes(value)
      );
      this.isShowStudentList = true;
      this.$nextTick(() => {
        // 获取输入框的位置
        const inputRect = this.$refs.studentInput.$el.getBoundingClientRect();
        // 设置学生列表的位置
        this.$refs.studentList.style.top = inputRect.bottom + 'px';
        // 学生列表的right为0，即与输入框右对齐
        this.$refs.studentList.style.right = 16 + 'px';
      });
    },
    onChooseStudent(item) {
      // 选择学生后更新表单数据，并隐藏学生列表
      this.form.name = item.name;
      this.form.userId = item.id;
      this.isShowStudentList = false;
    },
    onCalendarConfirm(date, type) {
      console.log(date);
      console.log(type);
      if (type === 'start') {
        this.endMinDate = date;
        this.startDate = date;
        if (this.endDate && date.getTime() > this.endDate.getTime()) {
          this.endDate = null;
          this.form.endTime = '';
        }
        this.form.startTime = moment(date).format('YYYY-MM-DD ');
        this.showStartCalendar = false;
      } else {
        this.endDate = date;
        this.form.endTime = moment(date).format('YYYY-MM-DD');
        this.showEndCalendar = false;
      }
    },
    formatterTime(type, value) {
      if (type === 'year') {
        return `${value}`;
      } else if (type === 'month') {
        return `${value}`;
      } else if (type === 'day') {
        return `${value} `;
      }
    },
    onStartTimeConfirm(value) {
      // 移除time校验结果
      this.$refs.form.resetValidation('startTime');

      this.form.startTime = dayjs(value).format('YYYY-MM-DD');
      this.showStartCalendar = false;
      this.$refs.form.validate('startTime');
      this.$refs.form.validate('endTime');
    },
    onEndTimeConfirm(value) {
      this.$refs.form.resetValidation('endTime');
      this.form.endTime = dayjs(value).format('YYYY-MM-DD ');
      this.showEndCalendar = false;
      this.$refs.form.validate('startTime');
      this.$refs.form.validate('endTime');
    },
    // 校验开始时间
    validatorStartTime(value) {
      if (this.form.endTime && dayjs(value).isAfter(this.form.endTime)) {
        return false;
      } else {
        return true;
      }
    }, // 校验结束时间
    validatorEndTime(value) {
      if (this.form.startTime && dayjs(value).isBefore(this.form.startTime)) {
        return false;
      } else {
        return true;
      }
    },
    // 校验请假学生非空
    validatorStudent(value) {
      if (this.type === 'student' && !value) {
        return false;
      } else if (this.type === 'student' && !this.form.userId) {
        return false;
      } else {
        return true;
      }
    },

    test() {
      this.showPicker = true;
      console.log(this.showPicker);
    },
    async onSubmit() {
      try {
        console.log('form:');
        console.log(this.form);
        // 校验表单
        this.showLoading = true;
        const params = {
          ...this.form,
          startTime: dayjs(this.form.startTime).format('YYYY-MM-DD 00:00:00'),
          endTime: dayjs(this.form.endTime).format('YYYY-MM-DD 23:59:59')
        };
        if (this.type === 'student') {
          // 班主任帮学生请假,manager填自己,channel填1, status填1,userId填学生id
          params.channel = 1;
          params.status = 1;
          params.manager = this.$store.getters.userId;
        }
        console.log(params, 'params');
        const { code, message } = await addLeave(params);
        if (code === 0) {
          Toast.success('申请成功！');
          this.$router.back();
        } else {
          console.log(message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.showLoading = false;
      }
    },
    openDialog() {
      this.$refs.form.validate().then(() => {
        this.showDialog = true;
      });
    },
    toHome() {
      this.$router.go(-1);
    },
    toBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  ::v-deep .van-dialog {
    border-radius: 10px;
    font-family: 'PingFangSC-Regular';
    width: 343px;
    height: 161px;
    box-sizing: border-box;
    .body {
      margin: 24px 0;
      text-align: center;
      font-weight: 400;
      color: #666666;
      font-size: 16px;
      box-sizing: border-box;
      line-height: 16px;
    }
  }
  ::v-deep .van-dialog__header {
    padding-top: 28px;
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 44px;
    color: white;
    overflow: hidden;
    /* border-bottom: 1px solid rgba(181, 201, 210, 0.6); */
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
      color: #79efcd;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    box-sizing: border-box;
    margin-top: 54px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form {
      width: 100%;
      box-sizing: border-box;
      padding-left: 16px;
    }
  }
}
::v-deep .van-picker__confirm,
::v-deep .van-picker__cancel,
::v-deep .van-field__label {
  font-size: 16px;
}
::v-deep .van-field__control {
  color: #666666;
}
::v-deep .van-field::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  transform: scaleY(0.5);
}
::v-deep .van-field {
  min-height: 45px;
  line-height: 45px;
  padding: 0;
  padding-right: 16px !important;
  font-weight: 400;
  color: #333333;
  font-family: 'PingFangSC-Regular';
}
::v-deep .van-field__error-message--right {
}
::v-deep .van-field__control::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #e5e5e5;
}
.apply-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 44px;
  background-color: #fa5050;
  color: #fff;
  border-radius: 4px;
}
::v-deep .van-picker__toolbar {
  height: 50px;
  background-color: #f1f1f1;
  .van-picker__cancel {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
  .van-picker__confirm {
    font-size: 16px;
    font-weight: 500;
    color: #fa5050;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}
::v-deep .van-picker-column__item {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #333333;
  font-family: 'PingFangSC-Regular, sans-serif';
}
.student-list {
  position: fixed;
  border-radius: 10px;
  overflow-y: auto; /* 添加滚动条 */
  width: 240px;
  max-height: 220px;
  background-color: #fff;
  z-index: 9999; /* 确保学生列表显示在其他内容上方 */
  box-shadow: 0 5px 15px 0 #00000014;
}

.student-list-item {
  width: 100%;
  height: 44px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  color: #666666;
  font-size: 16px;
  box-sizing: border-box;
  padding: 14px 0 14px 16px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
}
.submit-dialog {
  border-radius: 10px;
  font-family: 'PingFangSC-Regular';
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

::v-deep .van-field__body {
  min-height: 45px;
  input,
  textarea {
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    font-size: 16px;
    color: #666666;
  }
  textarea {
    height: auto;
  }
}

/* ::v-deep .van-field__error-message {
  width: 359px;
  position: relative;
  left: -110px;
}

::v-deep .van-field__control--right {
  width: calc(100% - 156px);
}

::v-deep .van-field__body {
  flex: 1;
}

::v-deep .van-field__value {
  display: flex;
} */
</style>
